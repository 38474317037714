
import {Component, Vue, Prop, Provide, Watch} from 'vue-property-decorator';
import {AddAddressReq} from "@/interface/res/mine";
import {phone} from "@/utils/regExp";
import {AddAddressApi, UpdateAddressApi} from "@/network/modules/mine";
import {mapGetters} from "vuex";
const area = require('@/utils/area')

@Component({
  components: {
  },
  computed: {
    ...mapGetters(["addressList"])
  }
})
export default class Dialog extends Vue {
  @Prop({ type: Object, default: ()=>{} }) readonly data ?: object;
  @Prop({ type: Boolean, default: false }) readonly show ?: boolean;
  @Prop({ type: Number, default: 0 }) readonly iType ?: number;


  @Provide() form:AddAddressReq = {}
  @Provide() areaList:Array<any> = area
  @Provide() addressCode:Array<any> = []
  @Provide() addressTitle:string = ''
  @Provide() loading:boolean = false
  @Provide() addressNameMsg:string = ''
  @Provide() addressPhoneMsg:string = ''
  @Provide() addressCodeMsg:string = ''
  @Provide() msgType:string = 'err'
  @Provide() msg:string = ''

  @Provide() dialogVisible: boolean = false
  @Watch('show',{immediate:false, deep:true})
  private iShowChange():void {
    const _this:any = this
    _this.dialogVisible = _this.show

    if(_this.show){

      if(_this.iType == 0){
        _this.addressTitle = '添加新地址'
        _this.dialogVisible = true
        _this.form.addressName =
            _this.form.addressPhone =
                _this.form.areaName =
                    _this.form.cityName =
                        _this.form.detailedAddress =
                            _this.form.provinceName = '';
        _this.form.provinceCode = _this.form.cityCode = _this.form.areaCode = -1;
        _this.form.defaultStatus = 0;
        _this.addressCode = [];
        return
      }

      _this.addressTitle = '修改收货地址'
      _this.form = {..._this.data}
      _this.addressCode = [_this.data.provinceCode,_this.data.cityCode,_this.data.areaCode]
    }


  }



  /**
   * @Author HS
   * @Date 2021/7/19 10:55 上午
   * @Description: 默认地址
   * @Params: { number ： val - true } [值]
   * @Return: null
   */
  changeFn(){
    const _this:any = this;
    _this.form.defaultStatus == 0 ? _this.form.defaultStatus = 1 : _this.form.defaultStatus = 0
    _this.$forceUpdate()
  }

  /**
   * @Author HS
   * @Date 2021/7/19 10:23 上午
   * @Description: 添加-修改地址
   * @Params: null
   * @Return: null
   */
  async addressFn(){
    const _this:any = this;

    if(_this.$base.isNull(_this.form.addressName)){
      _this.addressNameMsg = "请输入收货姓名"
      return
    }
    _this.addressNameMsg = ""

    if(_this.$base.isNull(_this.form.addressPhone)){
      _this.addressPhoneMsg = "请输入联系电话"
      return
    }
    if(!phone.test(_this.form.addressPhone)){
      _this.addressPhoneMsg = "请输入正确的手机号码"
      return
    }
    _this.addressPhoneMsg = ""

    if(_this.$base.isNull(_this.addressCode)){
      _this.addressCodeMsg = '请选择地址信息'
      return;
    }
    const addressNameArr = _this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels
    _this.form.provinceName = addressNameArr[0]
    _this.form.cityName = addressNameArr[1]
    _this.form.areaName = addressNameArr[2]
    _this.form.provinceCode = _this.addressCode[0]
    _this.form.cityCode = _this.addressCode[1]
    _this.form.areaCode = _this.addressCode[2]
    _this.addressCodeMsg = ''

    if(_this.$base.isNull(_this.form.detailedAddress)){
      _this.msgType = 'err'
      _this.msg = '请输入详细地址'
      return;
    }
    _this.msg = ''
    _this.loading = true
    let params:AddAddressReq = {..._this.form}
    let res:any = {}
    if(_this.iType == 0){
      res = await AddAddressApi(params)
    }
    else{
      res = await UpdateAddressApi(params)
    }
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.msgType = 'err'
      _this.msg = msg
      return
    }
    _this.msgType = 'success'
    _this.iType == 0 ? _this.msg = '已新增' : _this.msg = '已修改'
    let addressData = {...params}
    if(_this.iType == 0){
      addressData = data
      if(addressData.defaultStatus == 1){
        _this.addressList.map((item:any)=> item.defaultStatus = 0)
      }
      _this.$store.commit('SET_ADDRESS_LIST_PUSH',JSON.stringify(addressData))
    }
    else{

      const listIndex = _this.addressList.findIndex((item:any)=> item.addressId == addressData.addressId)
      if(addressData.defaultStatus == 1){
        _this.addressList.map(((item:any)=> item.defaultStatus = 0))
      }
      let list = [..._this.addressList]
      list[listIndex] = addressData
      _this.$store.commit('SET_ADDRESS_LIST',JSON.stringify(list))
    }
    let b = setTimeout(()=>{
      _this.$emit('dialogClose')
      _this.msg = ""
      clearTimeout(b)
    },1500)
  }

}
